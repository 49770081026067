<template>
  <div class="flex justify-center">

    <div class="border text-left w-100 md:max-w-4xl p-4 bg-white rounded shadow relative">

      <overly opacity="bg-opacity-20" class="rounded" v-if="loading">
        <spinner></spinner>
      </overly>

      <h2 class="text-center text-xl mb-4 w-100 md:w-96">Admit Card Download</h2>

      <form @submit.prevent="requestToAdmitCardDownload" ref="form" v-if="!admit_card_downloadable">

          <p class="mb-8">Type your phone number and SSC roll and click submit</p>

          <div class="mb-4">
            <label for="phone">Phone</label>
            <input-text id="phone" class="bg-gray-50" name="phone"></input-text>
          </div>

          <div class="mb-4">
            <label for="roll">SSC Roll Number</label>
            <input-text id="roll" class="bg-gray-50" name="roll"></input-text>
          </div>

          <p  v-if="form_has_error" class="text-center text-red-500">{{form_has_error}}</p>

          <div class="mt-8 flex justify-center">
            <btn variant="info" :disabled="loading">Submit</btn>
          </div>

      </form>

      <div class="mt-6" v-else>

        <p class="text-center text-green-600">Your Admit card is ready</p>

        <div class="mt-8 flex justify-center">
          <btn variant="success" :href="pdf_link">Download Admit Card</btn>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import InputText from "../../../components/input/input-text";
import {ref} from "vue";
import axios from "../../../store/axios";
import {useRoute} from "vue-router";
import Btn from "../../../components/btn";
import Overly from "../../../components/overly";
import Spinner from "../../../components/spinner";

export default {
  name: "Download",
  components: {Spinner, Overly, Btn, InputText},
  setup(){
    const admit_card_downloadable = ref(false);
    const loading = ref(false);
    const form_has_error = ref('');
    const route = useRoute();
    const form = ref();
    const pdf_link = ref('');


    async function requestToAdmitCardDownload(){

      const url = `/data-collection-forms/${route.params.id}/request-to-download`;
      const formData = new FormData( form.value );

      loading.value = true;
      form_has_error.value = '';

      try {

        const {data} = await axios().post( url, formData );
        pdf_link.value = data.pdf_link;
        admit_card_downloadable.value = true;

      } catch ({response}){
        form_has_error.value = response.data.message;
      } finally {
        loading.value = false;
      }

    }

    return {
      loading,
      form_has_error,
      pdf_link,
      form,
      admit_card_downloadable,
      requestToAdmitCardDownload
    }

  }
}
</script>

<style scoped>

</style>